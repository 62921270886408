export default {
  isSizeOnDoubleCharge(size, unit) {
    if (size) {
      const dimension = size.split(' x ') // length, width, height
      if (unit === 'cm') {
        if (dimension[0] > 50 || dimension[1] > 38 || dimension[2] > 35) {
          return 1
        }
      }
      if (unit === 'in') {
        if (dimension[0] > 19.6 || dimension[1] > 14.9 || dimension[2] > 13.7) {
          return 1
        }
      }
      if (unit === 'ft') {
        if (dimension[0] > 1.6 || dimension[1] > 1.2 || dimension[2] > 1.1) {
          return 1
        }
      }
      return 0
    }
    return 0
  },

  isSizeOnMaxLimitation(size, unit) {
    if (size) {
      const dimension = size.split(' x ') // length, width, height
      if (unit === 'cm') {
        if (dimension[0] > 100 || dimension[1] > 76 || dimension[2] > 70) {
          return true
        }
      }
      if (unit === 'in') {
        if (dimension[0] > 39.2 || dimension[1] > 29.8 || dimension[2] > 27.4) {
          return true
        }
      }
      if (unit === 'ft') {
        if (dimension[0] > 3.2 || dimension[1] > 2.4 || dimension[2] > 2.2) {
          return true
        }
      }
      return false
    }
    return 0
  },

  isWeightOnMaxLimitation(weight) {
    return weight > 40
  },

  isWeightOnDoubleCharge(weight) {
    return (weight > 15 && weight <= 40) ? 1 : 0
  },
}
